<template>
  <div>
    <template v-if="tipo === 'INTEIRO' || tipo === 'DECIMAL'">
      <validation-provider
        v-slot="{ errors }"
        rules="required"
      >
        <v-text-field
          v-model="valor"
          type="number"
          label="Valor"
          prepend-icon="mdi-numeric"
          style="padding-bottom: 30px"
          hide-details
          single-line
          outlined
          :error-messages="errors"
        />
      </validation-provider>
    </template>
    <template v-else-if="tipo === 'DATA'">
      <!-- INPUT EXCLUSIVO PARA CAMPO mes_dados -->
      <v-menu
        v-if="campo.toLowerCase() === 'mes_dados'"
        v-model="menuCampoData"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <validation-provider
            v-slot="{ errors }"
            rules="required|min:7"
          >
            <v-text-field
              class="pt-0"
              v-model="dateFormatted"
              v-mask="'##/####'"
              label="Data"
              prepend-icon="mdi-calendar"
              outlined
              v-bind="attrs"
              v-on="on"
              @blur="(date = parseMonth(dateFormatted)), (valor = date)"
              :error-messages="errors"
            />
          </validation-provider>
        </template>
        <v-date-picker
          v-model="date"
          type="month"
          @input="
            (menuCampoData = false),
              (dateFormatted = formatMonth(date)),
              (valor = date)
          "
          locale="pt-br"
        />
      </v-menu>
      <!-- INPUT TYPE DATA -->
      <v-menu
        v-else
        v-model="menuCampoData"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <validation-provider
            v-slot="{ errors }"
            rules="required|min:10"
          >
            <v-text-field
              class="pt-0"
              v-model="dateFormatted"
              v-mask="'##/##/####'"
              label="Data"
              prepend-icon="mdi-calendar"
              outlined
              v-bind="attrs"
              v-on="on"
              @blur="(date = parseDate(dateFormatted)), (valor = date)"
              :error-messages="errors"
            />
          </validation-provider>
        </template>
        <v-date-picker
          v-model="date"
          @input="
            (menuCampoData = false),
              (dateFormatted = formatDate(date)),
              (valor = date)
          "
          locale="pt-br"
        />
      </v-menu>
    </template>
    <template v-else-if="tipo === 'LISTA'">
      <validation-provider
        v-slot="{ errors }"
        rules="required"
      >
        <v-textarea
          class="textarea-pdc"
          v-model="valor"
          label="Valor"
          prepend-icon="mdi-format-text"
          hint="Um valor por linha"
          rows="5"
          filled
          clearable
          no-resize
          :error-messages="errors"
        />
      </validation-provider>
    </template>
    <template v-else>
      <validation-provider
        v-slot="{ errors }"
        rules="required"
      >
        <v-text-field
          class="pb-0"
          v-model="valor"
          label="Valor"
          prepend-icon="mdi-format-text"
          outlined
          :error-messages="errors"
        />
      </validation-provider>
    </template>
  </div>
</template>

<script>
import dateFormatterMixins from '@/mixins/dateFormatterMixins';

export default {
  mixins: [dateFormatterMixins],

  props: {
    campo: {
      type: String,
      default: ''
    },
    tipo: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    valor: null,
    menuCampoData: false,
    dateFormatted: '',
    date: ''
  }),

  methods: {
    exportValorDefault() {
      return this.valor ? this.valor : '';
    }
  },

  watch: {
    campo() {
      this.valor = null;
    }
  }
};
</script>

<style>
.textarea-pdc textarea {
  font-size: 11px !important;
}
</style>
